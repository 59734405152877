import pageType from 'Addons/search/pageType.ts';
import { makeGetLocalPreselectionFromBreadcrumbs } from '../common.js';
import { bigcommerceAppClientId } from 'Stores/_common/constants.js';

const customer = getCustomerAsync();

export default {
  getLocalPreselection: makeGetLocalPreselectionFromBreadcrumbs(
    '.breadcrumbs .breadcrumb, .breadcrumbs .breadcrumb-text',
    { [pageType.category]: 'category', [pageType.brand]: 'brand_name' },
    (...elements) => {
      const isMetaFromZero = elements.some((el) => el.querySelector('meta')?.getAttribute('content') === '0');

      return elements
        .map((elem) => {
          // breadcrumbs template must have similar meta element:
          // <meta itemprop="position" content="{{@index}}" />
          const text = (elem.textContent || elem.innerText).trim();
          const metaAttr = elem.querySelector('meta')?.getAttribute('content');
          const ignore =
            text.toLowerCase() === 'home' || (isMetaFromZero ? metaAttr === '0' : metaAttr === '1');
          return !ignore && text;
        })
        .filter(Boolean);
    },
  ),
  getCustomerAsync: () => customer,
};

async function getCustomerAsync() {
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
  };

  return fetch(`/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, options)
    .then((response) => {
      if (response.ok && !response.redirected) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((response) => {
      const [, jsonPart] = response.token.split('.');
      const { customer } = JSON.parse(atob(jsonPart));
      if (customer) {
        window.Convermax.customerGroupId = customer.group_id;
        window.Convermax.customerId = customer.id;
        window.Convermax.isLoggedIn = !!customer;
        return customer;
      }
      window.Convermax.isLoggedIn = false;
      return null;
    })
    .catch((err) => {
      if (err.status !== 404) {
        console.error(err);
        window.Convermax.isLoggedIn = null;
      } else {
        window.Convermax.isLoggedIn = false;
      }
      return null;
    });
}
