//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-436:_7341,_2312,_984,_9356,_3904,_8084,_4344,_3176;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-436')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-436', "_7341,_2312,_984,_9356,_3904,_8084,_4344,_3176");
        }
      }catch (ex) {
        console.error(ex);
      }