import pageType from 'Addons/search/pageType.ts';

export default {
  searchPageUrl: '/search/',
  page: {
    typeToSelectorMap: {
      [pageType.home]:
        'body.home, body.page-type-default, body.type-default, body.default, main.pages-css-home, body.page--default, [data-content-region="home_below_menu"]',
      [pageType.category]:
        '.category .page-content, body.page-type-category, body.type-category, body.category, main.pages-css-category, body.page--category, .page.category, [data-content-region="category_below_header"]',
      [pageType.brand]:
        'body.page-type-brand, body.type-brand, body.brand, .main.brand, main.pages-css-brand, body.page--brand, .page.brand, [data-content-region="brand_below_header"]',
      [pageType.product]:
        'input[name="product_id"], body.page-type-product, body.type-product, body.product, main.pages-css-product, body.page--product',
    },
  },
};
