export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',

  SELECT_CLASS: 'form-select',

  FACET_BUTTON_CLASS: '',
  FACET_INPUT_CLASS: 'form-input',
  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',
  START_OVER: 'Clear All',

  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  SEARCH_BOX_CLASS: 'form quick-search-nav',
  SEARCH_BOX_FORM_CLASS: 'form-field form-prefixPostfix',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'button-search',
  SEARCH_BOX_DIALOG_OPENING_ICON_CLASS: 'navUser-action navUser-action--quickSearch',

  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',
  GO_TEXT: 'ADD',
  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',

  VERIFY_FITMENT_TITLE: 'Add a vehicle to check fitment',
  VERIFY_FITMENT_ICON: `
    <rt-virtual rt-if="this.fits === 'yes' || this.fits === 'universal'" template="icons/success" />
    <rt-virtual rt-if="this.fits === 'no'" template="icons/error" />
    <rt-virtual rt-if="this.fits === 'unknown'" template="icons/questionmark" />
    <rt-virtual rt-if="this.fits === 'aggressive'" template="icons/exclamationmark" />`,
  VERIFY_FITMENT_FITS: 'Compatible with',
  VERIFY_FITMENT_NOT_FIT: 'Does Not Fit',

  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',
};
