
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function collapsibleSingleVehicleGarageRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3578'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3780'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7741'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7744'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2874'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3578'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3780'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7741'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7744'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2874'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__header cm_vehicle-widget__single-vehicle-garage cm_vehicle-widget__collapsible' }, !this.withCollapsing ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns',
        'key': '157'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ADD\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    Clear\n  ')))) : null, this.withCollapsing ? _createElement('div', {
        'className': 'cm_vehicle-widget_header',
        'onClick': this.toggleCollapsed,
        'key': '6888'
    }, _createElement('span', { 'className': 'cm_vehicle-widget_header-label-icon' }, [_createElement('svg', {
            'width': '21',
            'height': '9',
            'viewBox': '0 0 21 9',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '70490'
        }, _createElement('path', {
            'd': 'M19.7613 7.08714C19.8746 6.35094 19.7259 5.69968 19.3224 5.10506C18.7065 4.21312 17.5952 3.78131 16.5404 4.02907C15.4857 4.26975 14.6857 5.13337 14.5088 6.20936C14.4592 6.52791 14.4521 6.84646 14.53 7.15085C14.5866 7.3703 14.4663 7.33491 14.346 7.33491C12.2364 7.33491 10.1199 7.33491 8.01035 7.33491H6.94852C7.38033 5.50148 6.06366 4.09278 4.61249 3.97244C2.96311 3.83086 1.48362 5.2254 1.73139 7.08007C1.44823 6.93849 1.18631 6.80399 0.910235 6.67657C0.825289 6.6341 0.81821 6.57747 0.81821 6.4996C0.81821 5.728 0.811131 4.94932 0.81821 4.17772C0.832367 3.51231 1.32789 2.98847 2.07117 2.83982C2.72243 2.7124 3.37369 2.58498 4.02494 2.46464C4.30102 2.41508 4.5771 2.38677 4.86025 2.38677C9.08634 2.38677 13.3124 2.38677 17.5385 2.38677C17.6235 2.38677 17.7155 2.38677 17.8358 2.38677C17.4819 1.6718 17.135 0.985149 16.7811 0.277261C17.2625 0.277261 17.7155 0.256024 18.1685 0.277261C18.48 0.298497 18.7419 0.454233 18.9614 0.673678C19.3932 1.09841 19.8179 1.53022 20.2427 1.95496C20.54 2.25227 20.6674 2.61329 20.6674 3.03095C20.6674 4.0503 20.6674 5.06966 20.6674 6.08194C20.6674 6.45005 20.5258 6.70488 20.1931 6.85354C20.0445 6.91725 19.9029 6.99512 19.7401 7.08007L19.7613 7.08714ZM11.4719 3.52647C11.3516 3.52647 11.2383 3.52647 11.118 3.52647C10.7428 3.52647 10.7499 3.52647 10.7499 3.90165C10.7499 4.02907 10.7924 4.06446 10.9127 4.06446C11.21 4.05738 11.5002 4.06446 11.7976 4.06446C12.1869 4.06446 12.1869 4.06446 12.1727 3.67512C12.1727 3.55478 12.1303 3.51939 12.017 3.52647C11.8329 3.53355 11.6489 3.52647 11.4648 3.52647H11.4719ZM14.3106 4.05738C14.4238 4.05738 14.53 4.05738 14.6433 4.05738C15.0538 4.05738 15.0468 4.05738 15.0184 3.64681C15.0114 3.5477 14.976 3.52647 14.8839 3.52647C14.5017 3.52647 14.1194 3.52647 13.7301 3.52647C13.6735 3.52647 13.5956 3.50523 13.5956 3.59726C13.5956 3.74591 13.546 3.90873 13.6168 4.02907C13.6735 4.11401 13.8221 4.05738 13.9283 4.05738C14.0557 4.05738 14.1761 4.05738 14.3035 4.05738H14.3106Z',
            'fill': 'white'
        }), _createElement('path', {
            'd': 'M4.37183 8.75075C3.18966 8.75075 2.24109 7.7951 2.24109 6.61292C2.24109 5.45199 3.20382 4.50342 4.37891 4.50342C5.55401 4.50342 6.50258 5.46615 6.4955 6.6554C6.4955 7.80218 5.52569 8.75075 4.37183 8.75075ZM5.07972 6.62C5.07972 6.23774 4.75409 5.91919 4.36475 5.91919C3.97541 5.91919 3.64979 6.2519 3.65687 6.64832C3.66394 7.03058 3.99665 7.34205 4.37891 7.33497C4.76117 7.33497 5.07972 7.00226 5.07264 6.62H5.07972Z',
            'fill': 'white'
        }), _createElement('path', {
            'd': 'M19.2658 6.62708C19.2658 7.78802 18.3101 8.75075 17.1421 8.75075C15.967 8.75075 15.0114 7.80218 15.0114 6.63416C15.0114 5.46615 15.9599 4.50342 17.1209 4.50342C18.303 4.50342 19.2587 5.45199 19.2658 6.62708ZM17.135 5.91919C16.7528 5.91919 16.4271 6.23774 16.4271 6.62C16.4271 7.00934 16.7528 7.33497 17.1492 7.33497C17.5314 7.33497 17.85 7.00226 17.8429 6.62C17.8429 6.23774 17.5173 5.91919 17.135 5.91919Z',
            'fill': 'white'
        }), _createElement('path', {
            'd': 'M7.95373 1.81346C8.49173 1.41704 8.98725 1.0277 9.51109 0.673756C9.94998 0.383522 10.4526 0.249023 10.9764 0.249023C11.6843 0.249023 12.3922 0.249023 13.1001 0.249023C13.2983 0.249023 13.2983 0.298576 13.2417 0.46139C13.093 0.871965 12.9656 1.28254 12.8311 1.69312C12.8028 1.79222 12.7674 1.84885 12.64 1.84885C11.1038 1.84885 9.56772 1.84885 8.0316 1.84885C8.01744 1.84885 8.00329 1.84177 7.94666 1.82054L7.95373 1.81346Z',
            'fill': 'white'
        }), _createElement('path', {
            'd': 'M16.9864 1.84177C16.3918 1.84177 15.8396 1.84177 15.2875 1.84177C14.707 1.84177 14.1195 1.84177 13.539 1.84177C13.3974 1.84177 13.3337 1.84177 13.3974 1.6648C13.5531 1.24715 13.6876 0.815334 13.8221 0.390601C13.8575 0.284418 13.9071 0.249023 14.0274 0.249023C14.6999 0.249023 15.3724 0.249023 16.0449 0.249023C16.1299 0.249023 16.2006 0.249023 16.2431 0.341049C16.4838 0.829492 16.7245 1.31086 16.9864 1.84177Z',
            'fill': 'white'
        }))]), _createElement('span', { 'className': 'cm_vehicle-widget_header-label' }, !this.isVehicleSelected ? ['Vehicle: Add a Vehicle'] : null, this.isVehicleSelected ? [
        'Vehicle: ',
        this.vehicleString
    ] : null), !this.collapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle up',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '111740'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, this.collapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle down',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '114920'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null) : null, this.withCollapsing ? _createElement('div', {
        'className': 'cm_collapsible-elem',
        'key': '11818'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ADD\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    Clear\n  '))))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]